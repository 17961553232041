.IconoBandera {
  /*width: 8%;*/
  /*width: 80px;*/
  width: min(12vh, 80px);
  height: auto;
  margin-bottom: 10px;
  margin-left: 18px;
  border-radius: 5px;
  /*max-width: 85vw; /* La imagen tendrá un ancho máximo del 85% del ancho de la ventana */
  /*width: auto; /* Permitir que la imagen se ajuste proporcionalmente */
  /*height: auto; /* Permitir que la altura de la imagen se ajuste proporcionalmente */
}





.NivelesGrid {
  margin-bottom: 40px;
}

.NivelGridElem {
  background-color: #21293e;
  color: #c5d1eb;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 1em; /* o 1rem */
  text-align: center;

  border-width: 2px; /* Ancho del borde */
  border-style: solid; /* Estilo del borde */
}


.NivelCompletoGridElem {
  border-color: #9fef00;
  color: #9fef00; /* Color de texto para niveles completos */
}

.NivelIncompletoGridElem {
  border-color: #ff8484;
  color: #ffffff; /* Color de texto para niveles incompletos */
  opacity: 0.2;
}



.DireccionValor {
  color: #c5d1eb;
  font-size: min(2.5vw, 1.75rem);
}

.DireccionEtiqueta {
  color: #c5d1eb;
  font-size: min(1.5vw, 1.05rem);
}



.contenedor {
  display: flex;
  align-items: center;
  justify-content: center; /* Centrar horizontalmente */
  font-size: 2em; /* Tamaño de fuente más grande */
  color: #9fef00; /* Color deseado */
}

.etiqueta {
  /*font-size: 18px;*/
  font-size: min(1.5vw, 18px);
  margin-right: 5px;
}

.valor {
  /*font-size: 50px; */
  font-size: min(5.2vw, 50px);
}



.TituloSeccionPerfil {
  text-align: center;
  color: #9fef00;
  margin: 20px;
}


.social-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #c5d1eb;
  /*font-family: Arial, sans-serif;*/
  font-size: 16px;
  margin-bottom: 10px;
  /*padding-left:10px;*/
  /*padding-right:10px;*/
  padding: 10px;
  background-color: #21293e;
  border-radius: 10px;
}

.social-link img {
  width: 50px; /* Ajusta el tamaño de la imagen según sea necesario */
  height: auto;
  margin-right: 10px;
  /*filter: saturate(0); /* Convertir la imagen a blanco y negro */
  transition: filter 0.3s; /* Agregar una transición suave al cambio de filtro */
}

.social-link:hover {
  color: #5cb2ff;
  background-color: #2b3854;
  transition: background-color 0.3s ease, border-color 0.3s ease, text-shadow 0.3s ease;
}

.social-link:hover img {
  filter: brightness(120%) sepia(1) hue-rotate(195deg); /* Aplicar filtros para cambiar el color de la imagen al hacer hover */
}


.PerfilJugador {
  background-color: #141d2b;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.BodyPerfilJugador {
  width: 80%;
  margin: 0 auto; /* Centra el elemento horizontalmente */
  padding: 15px;
  /*background-color: #21293e; /* #1a2332 */
  /*border: 1px solid #9fef00; */
  margin-bottom: 50px;

  flex-grow: 1; /* Hace que el contenido ocupe todo el espacio restante */
}


.ContenedorRedesSociales {
  /*background-color:#1a2332;*/
  /*border-color:#9fef00;
  border: 1px solid #9fef00;*/
  display: flex;
  align-items: center;
  justify-content: center; /* Centrar horizontalmente */
  flex-direction: column;
  font-size: 2em; /* Tamaño de fuente más grande */
  color: #9fef00; /* Color deseado */
  /*margin-left: 5%;*/
  max-width: 70%;
  margin: 0 auto 40px auto; /* margen superior 0, margen derecho e izquierdo auto, margen inferior 40px */
  gap: 10px;
}


.DescripcionJugador {
  color: #c5d1eb;
  text-align: center;
  background-color:#1a2332;
  padding: 15px;
  margin-bottom: 50px;
  border-radius: 20px;
  max-width: 1250px;
  margin-bottom: 50px; /* Margen inferior de 90px */
  margin-left: auto; /* Margen izquierdo automático */
  margin-right: auto; /* Margen derecho automático */
  /*margin: 0 auto;*/
}



.form-group {
  margin-bottom: 20px;
}

label {
  color: #9fef00;
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  /*border: 1px solid #9fef00;*/
  color: white;
  background-color: #141d2b;
}

.social-media-input {
  display: flex;
  flex-direction: column;
}

.social-media-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.social-media-input label,
.social-media-input img {
  margin-bottom: 5px;
}

.social-media-input img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}



textarea#description {
  height: 150px; /* Ajusta la altura según tu preferencia */
  max-height: 150px; /* Altura máxima */
  resize: vertical; /* Permite la redimension vertical */
  max-width: 100%; /* Ancho máximo */
}


/* Estilo para el textarea en estado de foco */
textarea:focus {
  outline: none; /* Quita el resplandor predeterminado al hacer clic */
  border-color: var(--border-color); /* Cambia el color del borde cuando está enfocado */
  box-shadow: 0 0 5px var(--shadow-color); /* Añade una sombra sutil cuando está enfocado */
}

/* Variables para los colores de la sombra del cuadro */
:root {
  --char-color: #9fef00; /* Color por defecto para la cantidad de caracteres */
  --near-max-color: #ffcc5c; /* Color para la cantidad cercana al máximo */
  --max-color: #ff8484; /* Color cuando se alcanza el máximo */
}

/* Estilo para los caracteres según la cantidad escrita */
.description-characters {
  color: var(--char-color);
}

/* Estilo cuando se alcanza la cantidad máxima */
.max-length-reached {
  color: var(--max-color);
}

/* Estilo cuando se acerca a la cantidad máxima */
.near-max-length {
  color: var(--near-max-color);
}







.ReactFlagsSelect-module_flagsSelect__2pfa2 .menu-flags {
  border: 1px solid #9fef00 !important;
}


.ReactFlagsSelect-module_selectValue__152eS, .ReactFlagsSelect-module_selectOption__3pcgW {
  margin: 0px !important;
  padding: 5px !important;
}


.ReactFlagsSelect-module_selectOptions {
  font-size: 16px !important;
}

.ReactFlagsSelect-module_filterBox__3m8EU {
  background-color: #141d2b !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid white !important;
}

.ReactFlagsSelect-module_selectOptionWithlabel__2GpmM {
  /*color: #9fef00 !important;*/
  color: #c5d1eb;
  background-color: #141d2b !important;
}

.ReactFlagsSelect-module_selectOptionWithlabel__2GpmM .ReactFlagsSelect-module_selectFlag svg {
  fill: #FF4B55 !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  max-height: 300px !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  color: #808080 !important;
  background-color: #141d2b !important;
  border: 1px solid #ccc !important;
}