.Ayuda {
  background-color: #141d2b;
  position: relative;
  overflow: hidden;
}

.AyudaContenedor {
  background-color: #21293e;
  min-height: 100%;
  display: block;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 60px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  color: #c5d1eb;
  border: 1px solid #9fef00;
}


@media (max-width: 1280px) {
  .AyudaContenedor {
    width: 80%;
  }
}


.AyudaTitulo {
  color: #9fef00;
  margin-bottom: 10px;
}


.AyudaFooter {
  /*display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; */

  display: flex;
  flex-direction: column;
  margin-top: 2.8%;
}

.MarginBottom15 {
  margin-bottom: 15px;
}

.MarginTop15 {
  margin-top: 15px;
}

/*
.AyudaIconoCategoria {
  margin-left: 5px;
  height: 30px;
}
*/