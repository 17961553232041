.EditarPerfil {
  background-color: #141d2b;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}


.BodyEditarPerfil {
  flex-grow: 1; /* Hace que el contenido ocupe todo el espacio restante */
}


.EditarPerfilContainer {
  width: 80%;
  margin: 0 auto; /* Centra el elemento horizontalmente */
  padding: 15px;
  /*background-color: #21293e; */
  background-color: #1a2332;
  border: 1px solid #9fef00;
  margin-bottom: 50px;
}


.EstadoEditarPerfil {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.EstadoEditarPerfilContainer {
  position: relative;
  min-height: 70px; /* Altura mínima para reservar espacio */
}


.ErrorPuntosInsuficientes {
  color: #ff8484;
  margin-top: 1px;
}




/*
i {
  color: #ffcc5c;
}
*/

.icono-info {
  color: #ffcc5c;
}


.info-icon-container {
  position: relative;
  display: inline-block;
}

.info-message {
  position: absolute;
  top: calc(100% - 25px); /* Ajusta la posición vertical del mensaje */
  left: 25px;
  display: none;
  background-color: #2b3854; /* Cambia el color de fondo del mensaje según sea necesario */
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Agrega sombra al mensaje */
  width: 500px;
  border: 1px solid #ffcc5c;
  color: #ffcc5c;
}

.info-icon-container:hover .info-message {
  display: block;
}






/* https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #2b3854;
}





.disabled-input {
  position: relative;
}


.disabled-input:disabled {
  background-image: linear-gradient(
    to top left,
    transparent 49.5%,
    #ff8484 50%,
    #ff8484 51%,
    transparent 51.5%
  );
  background-size: 15px 15px;
}


.ReactFlagsSelect-module_disabledBtn__3A4GF  {
  background-image: linear-gradient(
    to top left,
    transparent 49.5%,
    #ff8484 50%,
    #ff8484 51%,
    transparent 51.5%
  ) !important;
  background-size: 15px 15px !important;
}


.label-disabled {
  color: #c5d1eb;
}






.form-group {
  margin-bottom: 20px;
}

label {
  color: #9fef00;
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  /*border: 1px solid #9fef00;*/
  color: white;
  background-color: #141d2b;
}

.social-media-input {
  display: flex;
  flex-direction: column;
}

.social-media-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.social-media-input label,
.social-media-input img {
  margin-bottom: 5px;
}

.social-media-input img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}



textarea#descripcion {
  height: 150px; /* Ajusta la altura según tu preferencia */
  max-height: 150px; /* Altura máxima */
  resize: vertical; /* Permite la redimension vertical */
  max-width: 100%; /* Ancho máximo */
}


/* Estilo para el textarea en estado de foco */
textarea:focus {
  outline: none; /* Quita el resplandor predeterminado al hacer clic */
  border-color: var(--border-color); /* Cambia el color del borde cuando está enfocado */
  box-shadow: 0 0 5px var(--shadow-color); /* Añade una sombra sutil cuando está enfocado */
}

/* Variables para los colores de la sombra del cuadro */
:root {
  --char-color: #9fef00; /* Color por defecto para la cantidad de caracteres */
  --near-max-color: #ffcc5c; /* Color para la cantidad cercana al máximo */
  --max-color: #ff8484; /* Color cuando se alcanza el máximo */
}

/* Estilo para los caracteres según la cantidad escrita */
.descripcion-characters {
  color: var(--char-color);
}

/* Estilo cuando se alcanza la cantidad máxima */
.max-length-reached {
  color: var(--max-color);
}

/* Estilo cuando se acerca a la cantidad máxima */
.near-max-length {
  color: var(--near-max-color);
}





.MensajeErrorEditarPerfil {
  color: #ff8484;
  text-align: center;
  margin-bottom: 40px;
}







.ReactFlagsSelect-module_flagsSelect__2pfa2 .menu-flags {
  border: 1px solid #9fef00 !important;
}


.ReactFlagsSelect-module_selectValue__152eS, .ReactFlagsSelect-module_selectOption__3pcgW {
  margin: 0px !important;
  padding: 5px !important;
}


.ReactFlagsSelect-module_selectOptions {
  font-size: 16px !important;
}

.ReactFlagsSelect-module_filterBox__3m8EU {
  background-color: #141d2b !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid white !important;
  background-color: red !important;
}

.ReactFlagsSelect-module_selectOptionWithlabel__2GpmM {
  /*color: #9fef00 !important;*/
  color: #c5d1eb;
  background-color: #141d2b !important;
}

.ReactFlagsSelect-module_selectOptionWithlabel__2GpmM .ReactFlagsSelect-module_selectFlag svg {
  fill: #FF4B55 !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  max-height: 300px !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  color: #808080 !important;
  background-color: #141d2b !important;
  border: 1px solid #ccc !important;
}