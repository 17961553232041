.App {
  text-align: center;
  background-color: #141d2b;
  z-index: 0;
  /*padding-bottom: 40px;*/
  min-height: 100vh; /* Establece el mínimo alto del contenedor principal */


  margin: 0;
  display: flex;
  flex-direction: column;
}


.custom-navbar {
  background-color: #2b3854;
  border-bottom: 2px solid #9fef00;
  border-top: 2px solid #9fef00;
  margin-bottom: 50px;
}

.nav-items {
  display: flex;
  align-items: center;
  justify-content: space-around; /* Espacio igual entre los elementos */
  padding: 10px 50px; /* Ajusta el relleno según sea necesario */
  /*flex-wrap: wrap; /* Permite que los elementos se envuelvan a la siguiente línea */
}

.nav-infoJugador {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: left;
}

.nav-botones {
  margin: 0 auto;
  display: flex;
  align-items: right;
  justify-content: right;
  width: 100%;
}

.nav-elem {
  color: #9fef00;
  /*font-weight: bold;*/
  /* margin-right: 20px;   Ajusta el margen derecho según sea necesario */
  /*flex-basis: calc(25% - 8px); /* Establece un ancho base para cada elemento */
}

.nav-elem:not(:last-child) {
  margin-right: 30px; /* Agrega un margen de 4px a la derecha de cada elemento excepto al último */
}


/* Cambiar a 980px si se quita el botón "Encuesta" */
@media screen and (max-width: 1280px) {

  
  .nav-items {
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan a la siguiente línea */
    justify-content: center; /* Centrar los elementos horizontalmente */
    align-items: center;
  }


  .nav-infoJugador {
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan a la siguiente línea */
    justify-content: center;
    align-items: center;
    padding-bottom: 0.7%;
    
  }
  
  .nav-botones {
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan a la siguiente línea */
    justify-content: center;
    align-items: center;
    gap: 10px; /* Espacio vertical entre los elementos */
  }
  


  /*
  .nav-elem:nth-child(1) {
    margin-left: 55px; /* Elimina el margen derecho del segundo elemento 
  }

  .nav-elem:nth-child(2) {
    margin-right: -35px; /* Elimina el margen derecho del segundo elemento 
  }
  */



  .nav-infoJugador {
    margin-bottom: 5px;
  }
}





.jugador-address {
  font-size: 0.9em; /* Cambia el tamaño de fuente según tu preferencia */
  /*color: #c5f467;*/
}



/* Estilo específico para el elemento de texto sin enlace 
.nav-text {
  color: #333; Color de texto, puedes ajustarlo según tu diseño 
  font-weight: bold; Otra propiedad de estilo que desees agregar 
  Otros estilos según sea necesario 
}
*/

.LetraVerde {
  color: #9fef00;
}

.LetraAmarilla {
  color: #ffcc5c;
}

.LetraRoja {
  color: #ff8484;
}

.LetraAzul {
  color: #5cb2ff;
}

.LetraGris {
  color: #c5d1eb;
}

.PuntosJugador {
  color: #9fef00;
}


.btn-outline-success {
  color: #9fef00 !important; /* Color del texto */
  border: 1px solid #9fef00 !important; /* Color del borde */
  background-color: transparent !important; /* Fondo transparente */
}


.encuesta-button {
  border: 2px solid #9fef00 !important; /* Nuevo color del borde para el botón de la encuesta */
}


.btn-outline-success:hover {
  color: #141d2b !important; /* Color del texto */
  background-color: #9fef00 !important; /* Cambia el fondo al pasar el ratón por encima */
}


.btn-success {
  color: #141d2b !important; /* Color del texto */
  border: 1px solid #9fef00 !important; /* Color del borde */
  background-color: #9fef00 !important; /* Fondo transparente */
}

.btn-success:hover {
  color: #141d2b !important; /* Color del texto */
  background-color: #c5f467 !important; /* Cambia el fondo al pasar el ratón por encima */
}


.btn-outline-danger {
  color: #ff8484 !important; /* Color del texto */
  border: 1px solid #ff8484 !important; /* Color del borde */
  background-color: transparent !important; /* Fondo transparente */
}

.btn-outline-danger:hover {
  color: #141d2b !important; /* Color del texto */
  background-color: #ff8484 !important; /* Cambia el fondo al pasar el ratón por encima */
}


.btn-outline-warning {
  color: #ffcc5c !important; /* Color del texto */
  border: 1px solid #ffcc5c !important; /* Color del borde */
  background-color: transparent !important; /* Fondo transparente */
}

.btn-outline-warning:hover {
  color: #141d2b !important; /* Color del texto */
  background-color: #ffcc5c !important; /* Cambia el fondo al pasar el ratón por encima */
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Niveles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr)); /* Ajustar automáticamente, con mínimo de 250px y máximo de 1fr */
  /*grid-gap: 7.5vh 10%; /* Espacio entre los niveles (2% del ancho del contenedor) */
  grid-gap: 50px 10%;
  margin-left: 5%; /* Margen inicial a la izquierda (5% del ancho del contenedor) */
  margin-right: 5%; /* Margen inicial a la derecha (5% del ancho del contenedor) */
  justify-items: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
  /*margin-bottom: 4.5%;*/
  margin-bottom: 70px;
}


/*
.ContenedorPrincipal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /*min-height: 100vh;
}
*/


/* Importa la fuente local en formato .ttf */
@font-face {
  font-family: 'Digital-7';
  src: url('../fonts/digital-7.ttf') format('truetype');
}

@font-face {
  font-family: 'Future-n0t-Found';
  src: url('../fonts/Future n0t Found Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nightmare-Codehack';
  src: url('../fonts/Nightmare Codehack.otf') format('truetype');
}

@font-face {
  font-family: 'Mad-Hacker';
  src: url('../fonts/Mad Hacker.ttf') format('truetype');
}

@font-face {
  font-family: 'hackerchaos';
  src: url('../fonts/hackerchaos.otf') format('truetype');
}

@font-face {
  font-family: 'Positive-System';
  src: url('../fonts/Positive System.otf') format('truetype');
}


.glitchText {
  color: #c5d1eb;
  /*font-size: 7rem;*/
  /*font-size: 7vw; /* Tamaño de fuente en relación con el ancho de la ventana */
  font-size: min(8.5vw, 7rem);
  /*font-family: 'Mad-Hacker';*/
  font-family: 'Positive-System';
  position: relative;
  white-space: nowrap; /* Evita el salto de línea automático */
  /*line-height: 1;*/
  /*width: 12.5rem;*/
  max-width: 100%; /* Evita que el texto se extienda más allá del ancho de su contenedor */
}

@-webkit-keyframes noise-anim {
  0% {
    clip: rect(78px, 9999px, 4px, 0);
  }
  5% {
    clip: rect(87px, 9999px, 70px, 0);
  }
  10% {
    clip: rect(21px, 9999px, 60px, 0);
  }
  15% {
    clip: rect(79px, 9999px, 83px, 0);
  }
  20% {
    clip: rect(20px, 9999px, 28px, 0);
  }
  25% {
    clip: rect(42px, 9999px, 78px, 0);
  }
  30% {
    clip: rect(71px, 9999px, 37px, 0);
  }
  35% {
    clip: rect(89px, 9999px, 49px, 0);
  }
  40% {
    clip: rect(36px, 9999px, 48px, 0);
  }
  45% {
    clip: rect(77px, 9999px, 67px, 0);
  }
  50% {
    clip: rect(7px, 9999px, 94px, 0);
  }
  55% {
    clip: rect(90px, 9999px, 79px, 0);
  }
  60% {
    clip: rect(48px, 9999px, 57px, 0);
  }
  65% {
    clip: rect(64px, 9999px, 35px, 0);
  }
  70% {
    clip: rect(23px, 9999px, 88px, 0);
  }
  75% {
    clip: rect(68px, 9999px, 27px, 0);
  }
  80% {
    clip: rect(39px, 9999px, 98px, 0);
  }
  85% {
    clip: rect(97px, 9999px, 57px, 0);
  }
  90% {
    clip: rect(61px, 9999px, 53px, 0);
  }
  95% {
    clip: rect(79px, 9999px, 44px, 0);
  }
  100% {
    clip: rect(12px, 9999px, 19px, 0);
  }
}

@keyframes noise-anim {
  0% {
    clip: rect(78px, 9999px, 4px, 0);
  }
  5% {
    clip: rect(87px, 9999px, 70px, 0);
  }
  10% {
    clip: rect(21px, 9999px, 60px, 0);
  }
  15% {
    clip: rect(79px, 9999px, 83px, 0);
  }
  20% {
    clip: rect(20px, 9999px, 28px, 0);
  }
  25% {
    clip: rect(42px, 9999px, 78px, 0);
  }
  30% {
    clip: rect(71px, 9999px, 37px, 0);
  }
  35% {
    clip: rect(89px, 9999px, 49px, 0);
  }
  40% {
    clip: rect(36px, 9999px, 48px, 0);
  }
  45% {
    clip: rect(77px, 9999px, 67px, 0);
  }
  50% {
    clip: rect(7px, 9999px, 94px, 0);
  }
  55% {
    clip: rect(90px, 9999px, 79px, 0);
  }
  60% {
    clip: rect(48px, 9999px, 57px, 0);
  }
  65% {
    clip: rect(64px, 9999px, 35px, 0);
  }
  70% {
    clip: rect(23px, 9999px, 88px, 0);
  }
  75% {
    clip: rect(68px, 9999px, 27px, 0);
  }
  80% {
    clip: rect(39px, 9999px, 98px, 0);
  }
  85% {
    clip: rect(97px, 9999px, 57px, 0);
  }
  90% {
    clip: rect(61px, 9999px, 53px, 0);
  }
  95% {
    clip: rect(79px, 9999px, 44px, 0);
  }
  100% {
    clip: rect(12px, 9999px, 19px, 0);
  }
}

@-webkit-keyframes noise-anim-2 {
  0% {
    clip: rect(20px, 9999px, 91px, 0);
  }
  5% {
    clip: rect(20px, 9999px, 44px, 0);
  }
  10% {
    clip: rect(84px, 9999px, 55px, 0);
  }
  15% {
    clip: rect(40px, 9999px, 93px, 0);
  }
  20% {
    clip: rect(37px, 9999px, 73px, 0);
  }
  25% {
    clip: rect(75px, 9999px, 57px, 0);
  }
  30% {
    clip: rect(89px, 9999px, 38px, 0);
  }
  35% {
    clip: rect(6px, 9999px, 68px, 0);
  }
  40% {
    clip: rect(63px, 9999px, 17px, 0);
  }
  45% {
    clip: rect(55px, 9999px, 49px, 0);
  }
  50% {
    clip: rect(98px, 9999px, 28px, 0);
  }
  55% {
    clip: rect(18px, 9999px, 57px, 0);
  }
  60% {
    clip: rect(18px, 9999px, 64px, 0);
  }
  65% {
    clip: rect(35px, 9999px, 77px, 0);
  }
  70% {
    clip: rect(31px, 9999px, 59px, 0);
  }
  75% {
    clip: rect(30px, 9999px, 38px, 0);
  }
  80% {
    clip: rect(13px, 9999px, 52px, 0);
  }
  85% {
    clip: rect(63px, 9999px, 91px, 0);
  }
  90% {
    clip: rect(96px, 9999px, 67px, 0);
  }
  95% {
    clip: rect(24px, 9999px, 13px, 0);
  }
  100% {
    clip: rect(5px, 9999px, 29px, 0);
  }
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(20px, 9999px, 91px, 0);
  }
  5% {
    clip: rect(20px, 9999px, 44px, 0);
  }
  10% {
    clip: rect(84px, 9999px, 55px, 0);
  }
  15% {
    clip: rect(40px, 9999px, 93px, 0);
  }
  20% {
    clip: rect(37px, 9999px, 73px, 0);
  }
  25% {
    clip: rect(75px, 9999px, 57px, 0);
  }
  30% {
    clip: rect(89px, 9999px, 38px, 0);
  }
  35% {
    clip: rect(6px, 9999px, 68px, 0);
  }
  40% {
    clip: rect(63px, 9999px, 17px, 0);
  }
  45% {
    clip: rect(55px, 9999px, 49px, 0);
  }
  50% {
    clip: rect(98px, 9999px, 28px, 0);
  }
  55% {
    clip: rect(18px, 9999px, 57px, 0);
  }
  60% {
    clip: rect(18px, 9999px, 64px, 0);
  }
  65% {
    clip: rect(35px, 9999px, 77px, 0);
  }
  70% {
    clip: rect(31px, 9999px, 59px, 0);
  }
  75% {
    clip: rect(30px, 9999px, 38px, 0);
  }
  80% {
    clip: rect(13px, 9999px, 52px, 0);
  }
  85% {
    clip: rect(63px, 9999px, 91px, 0);
  }
  90% {
    clip: rect(96px, 9999px, 67px, 0);
  }
  95% {
    clip: rect(24px, 9999px, 13px, 0);
  }
  100% {
    clip: rect(5px, 9999px, 29px, 0);
  }
}

.glitchText:after {
  content: attr(datatext);
  white-space: pre;
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 #c5d1eb;
  top: 0;
  color: #9fef00;
  /*background: #f8f9fc;*/
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

.glitchText:before {
  content: attr(datatext);
  position: relative;
  left: -2px;
  text-shadow: 1px 0 #4e73df;
  top: 0;
  color: #9fef00;
  /*background: #f8f9fc;*/
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}



.App {
  position: relative;
  overflow: hidden;
}

.ParticlesContainer {
  position: absolute;
}

.ParticlesElem {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


.Header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #141d2b;
}


/*
.Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #c5d1eb;
  color: #9fef00;
}
*/

/*
.Footer {
  position: fixed; Fija la posición 
  bottom: 0; Lo coloca en la parte inferior de la ventana gráfica 
  left: 0; Lo coloca en el lado izquierdo de la ventana gráfica 
  width: 100%; Ocupa el ancho completo 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #9fef00;
  background-color: #141d2b; Agrega un color de fondo si lo deseas 
}
*/


.Footer {
  /*position: absolute;  Usa position: absolute */
  /*position: relative;*/ 
  bottom: 0; /* Colócalo en la parte inferior */
  width: 100%; /* Ocupa el ancho completo */
  background-color: #141d2b; /* Color de fondo */
  color: #9fef00; /* Color del texto */
  text-align: center; /* Alinea el texto al centro */
}


/* Posicionamiento absoluto cuando los datos están cargando */
.TextoCarga:not(:empty) + .Footer {
  position: absolute;
  bottom: 0;
}

/* Posicionamiento relativo cuando los datos están cargados */
.TextoCarga:empty + .Footer {
  position: relative;
}


.TextoCarga:empty {
  display: none; /* Oculta el elemento si está vacío (datos cargados) */
}

.TextoCarga {
  margin-top: 15vh; /* Añade un margen inferior de 5% del alto de la ventana */
}


.PuntosJugador {
  position: absolute;
  top: 1%;
  right: 0;
  /*background-color: #f00; Cambia esto al color que desees */
  width: 400px; /* Ancho del elemento */
  height: 50px; /* Altura del elemento */
  /* Agrega cualquier estilo adicional que necesites */
}


.IconoCategoria {
  margin-left: 5px;
  height: 30px;
}


.LogoMetaMask {
  margin-left: 5px;
  width: 30px;
  height: 30px;
}