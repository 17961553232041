.VentanaInstalarMetaMask {
  background-color: #141d2b;
  min-height: 100vh; /* Establece el mínimo alto del contenedor principal */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye uniformemente los elementos en el eje vertical */
}

.BodyInstalarMetaMask {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye uniformemente los elementos en el eje vertical */
  align-items: center;
  flex-grow: 1; /* Permite que el contenedor del cuerpo ocupe todo el espacio restante */
  margin-bottom: 5vh auto; /* Añade un margen inferior de 5% del alto de la ventana */
  /*margin-top: 5vh;*/
}

.TextoVentanaInstalarMetaMask,
.BotonVentanaInstalarMetaMask,
.NotaVentanaInstalarMetamask {
  text-align: center;
  width: 80%;
  max-width: 670px;
  margin-bottom: 20px;
}

@media (min-width: 1700px) {
  .BodyInstalarMetaMask {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
}

@media (min-width: 1900px) {
  .BodyInstalarMetaMask {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
}

@media (min-width: 2300px) {
  .BodyInstalarMetaMask {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
}

@media (min-width: 2700px) {
  .BodyInstalarMetaMask {
    margin-top: 15vh;
    margin-bottom: 15vh;
  }
}

@media (min-width: 3100px) {
  .BodyInstalarMetaMask {
    margin-top: 12vh;
    margin-bottom: 12vh;
  }
}

/*
@media (min-width: 769px) {
  .BodyInstalarMetaMask {
    margin-top: 50vh;
    margin-bottom: 50vh;
  }
}
*/


/*
.TextoVentanaInstalarMetaMask {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 15vh;
  padding-top: 90px;
}

.BotonVentanaInstalarMetaMask {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 35vh;
  padding-top: 110px;
}

.NotaVentanaInstalarMetamask {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 0vh;
}
*/