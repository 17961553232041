pre code.hljs {
  /*border: 1px solid #9fef00 !important;*/
  display: block;
  overflow-x: auto;
  padding: 1em;

  max-width: 100% !important; /* Establece un ancho máximo del 100% del contenedor padre */
  margin: 0 auto; /* Centra horizontalmente el elemento */
}

pre code.hljs.borde-resaltado {
  border: 1px solid #9fef00 !important;
}

code.hljs {
  padding: 3px 5px;
}

.hljs {
  color: #c5d1eb;
  background: #1a2332;
}

.hljs ::selection, .hljs::selection {
  background-color: #3e4451;
  color: #c5d1eb;
}

.hljs-comment {
  color: #a4b1cd;
  /* color: #c5f467 */
}

.hljs-tag {
  color: #a4b1cd;
}

.hljs-operator,
.hljs-punctuation,
.hljs-subst {
  color: #c5d1eb;
}

.hljs-operator {
  opacity: 0.7;
}

.hljs-bullet,
.hljs-deletion,
.hljs-name,
.hljs-selector-tag,
.hljs-template-variable,
.hljs-variable {
  /*color: #e06c75;*/
  color: #ff8484
}

.hljs-attr,
.hljs-link,
.hljs-literal,
.hljs-number,
.hljs-symbol,
.hljs-variable.constant_ {
  color: #ffaf00;
}

.hljs-class .hljs-title,
.hljs-title,
.hljs-title.class_ {
  color: #ffcc5c;
}

.hljs-strong {
  font-weight: 700;
  color: #ffcc5c;
}

.hljs-addition,
.hljs-code,
.hljs-string,
.hljs-title.class_.inherited__ {
  color: #9fef00;
}

.hljs-built_in,
.hljs-doctag,
.hljs-keyword.hljs-atrule,
.hljs-quote,
.hljs-regexp {
  color: #2e6cff;
}

.hljs-attribute,
.hljs-function .hljs-title,
.hljs-section,
.hljs-title.function_,
.ruby .hljs-property {
  color: #5cb2ff;
}

.diff .hljs-meta,
.hljs-keyword,
.hljs-template-tag,
.hljs-type {
  color: #cf8dfb;
}

.hljs-emphasis {
  color: #cf8dfb;
  font-style: italic;
}

.hljs-meta,
.hljs-meta .hljs-keyword,
.hljs-meta .hljs-string {
  color: #ff8484;
}

.hljs-meta .hljs-keyword,
.hljs-meta-keyword {
  font-weight: 700;
}