.Nivel {
  background-color: #141d2b;
  z-index: 0;
  min-height: 100vh; /* Establece el mínimo alto del contenedor principal */
  position: relative;
  overflow: hidden;


  margin: 0;
  display: flex;
  flex-direction: column;
}


.NivelBody {
  /*margin-bottom: 70px;*/
  margin-bottom: 30px;
  flex-grow: 1; /* Hace que el contenido ocupe todo el espacio restante */
}


.accordion-button {
  background-color: #21293e !important;
  box-shadow: 0 0 0 0.05rem #9fef00; /* Resalte difuminado blanco */
}


.accordion-button:not(.collapsed) {
  background-color: #1a2332 !important;
  box-shadow: 0 0 0 0.05rem #9fef00;
}

.accordion-body {
  background-color: #2b3854 !important;
  color: #c5d1eb !important;
}


.accordion-button:focus {
  box-shadow: 0 0 0 0.1rem #9fef00; /* Resalte difuminado blanco */
  /*border-color: #9fef00; Color del borde */
}



/*
.accordion-button {
  background-color: #1a2332 !important;
}


.accordion-button:not(.collapsed) {
  background-color: #141d2b !important;
}

.accordion-body {
  background-color: #21293e !important;
  color: #c5d1eb !important;
}
*/

/* Sobrescribe el color de los enlaces */
a {
  color: #5cb2ff; /* Reemplaza con tu color de enlace personalizado */
}

/* Cambia el color del enlace al pasar el ratón por encima */
a:hover {
  color: #2e6cff; /* Reemplaza con tu color de enlace al pasar el ratón por encima */
}


code {
  color: #9fef00;
}


/*
.NivelCodigo {
  box-shadow: 0 0 5px #9fef00; Añade una sombra sutil cuando está enfocado 
}
*/

.TextoInfoHeader {
  color: #c5d1eb;
}

.TextoError {
  color: #ff8484;
}


.btn-danger {
  background-color: #ff8484;
  color: #ffffff;
}

/* Cambia el color del texto al pasar el ratón por encima */
.btn-danger:hover {
  background-color: #ff3e3e;
  color: #ffffff;
}


/* styles.css */

/* Cambia el color de fondo del bloque de código */
.markdown-body pre code {
  background-color: #f0f0f0;
}

/* Cambia el color del texto del bloque de código */
.markdown-body code {
  color: #333;
}




/* Importa la fuente local en formato .ttf */
@font-face {
  font-family: 'Digital-7';
  src: url('../fonts/digital-7.ttf') format('truetype');
}

@font-face {
  font-family: 'Future-n0t-Found';
  src: url('../fonts/Future n0t Found Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nightmare-Codehack';
  src: url('../fonts/Nightmare Codehack.otf') format('truetype');
}

@font-face {
  font-family: 'Sunday-Bloom';
  src: url('../fonts/Sunday Bloom.ttf') format('truetype');
}

@font-face {
  font-family: 'Sunday-Bloom';
  src: url('../fonts/Sunday Bloom.ttf') format('truetype');
}

@font-face {
  font-family: 'Mad-Hacker';
  src: url('../fonts/Mad Hacker.ttf') format('truetype');
}

@font-face {
  font-family: 'hackerchaos';
  src: url('../fonts/hackerchaos.otf') format('truetype');
}


/*
.glitchText {
  color: #000000;
  font-size: 7rem;
  font-family: 'Mad-Hacker';
  position: relative;
  line-height: 1;
  width: 12.5rem;
}

@-webkit-keyframes noise-anim {
  0% {
    clip: rect(78px, 9999px, 4px, 0);
  }
  5% {
    clip: rect(87px, 9999px, 70px, 0);
  }
  10% {
    clip: rect(21px, 9999px, 60px, 0);
  }
  15% {
    clip: rect(79px, 9999px, 83px, 0);
  }
  20% {
    clip: rect(20px, 9999px, 28px, 0);
  }
  25% {
    clip: rect(42px, 9999px, 78px, 0);
  }
  30% {
    clip: rect(71px, 9999px, 37px, 0);
  }
  35% {
    clip: rect(89px, 9999px, 49px, 0);
  }
  40% {
    clip: rect(36px, 9999px, 48px, 0);
  }
  45% {
    clip: rect(77px, 9999px, 67px, 0);
  }
  50% {
    clip: rect(7px, 9999px, 94px, 0);
  }
  55% {
    clip: rect(90px, 9999px, 79px, 0);
  }
  60% {
    clip: rect(48px, 9999px, 57px, 0);
  }
  65% {
    clip: rect(64px, 9999px, 35px, 0);
  }
  70% {
    clip: rect(23px, 9999px, 88px, 0);
  }
  75% {
    clip: rect(68px, 9999px, 27px, 0);
  }
  80% {
    clip: rect(39px, 9999px, 98px, 0);
  }
  85% {
    clip: rect(97px, 9999px, 57px, 0);
  }
  90% {
    clip: rect(61px, 9999px, 53px, 0);
  }
  95% {
    clip: rect(79px, 9999px, 44px, 0);
  }
  100% {
    clip: rect(12px, 9999px, 19px, 0);
  }
}

@keyframes noise-anim {
  0% {
    clip: rect(78px, 9999px, 4px, 0);
  }
  5% {
    clip: rect(87px, 9999px, 70px, 0);
  }
  10% {
    clip: rect(21px, 9999px, 60px, 0);
  }
  15% {
    clip: rect(79px, 9999px, 83px, 0);
  }
  20% {
    clip: rect(20px, 9999px, 28px, 0);
  }
  25% {
    clip: rect(42px, 9999px, 78px, 0);
  }
  30% {
    clip: rect(71px, 9999px, 37px, 0);
  }
  35% {
    clip: rect(89px, 9999px, 49px, 0);
  }
  40% {
    clip: rect(36px, 9999px, 48px, 0);
  }
  45% {
    clip: rect(77px, 9999px, 67px, 0);
  }
  50% {
    clip: rect(7px, 9999px, 94px, 0);
  }
  55% {
    clip: rect(90px, 9999px, 79px, 0);
  }
  60% {
    clip: rect(48px, 9999px, 57px, 0);
  }
  65% {
    clip: rect(64px, 9999px, 35px, 0);
  }
  70% {
    clip: rect(23px, 9999px, 88px, 0);
  }
  75% {
    clip: rect(68px, 9999px, 27px, 0);
  }
  80% {
    clip: rect(39px, 9999px, 98px, 0);
  }
  85% {
    clip: rect(97px, 9999px, 57px, 0);
  }
  90% {
    clip: rect(61px, 9999px, 53px, 0);
  }
  95% {
    clip: rect(79px, 9999px, 44px, 0);
  }
  100% {
    clip: rect(12px, 9999px, 19px, 0);
  }
}

@-webkit-keyframes noise-anim-2 {
  0% {
    clip: rect(20px, 9999px, 91px, 0);
  }
  5% {
    clip: rect(20px, 9999px, 44px, 0);
  }
  10% {
    clip: rect(84px, 9999px, 55px, 0);
  }
  15% {
    clip: rect(40px, 9999px, 93px, 0);
  }
  20% {
    clip: rect(37px, 9999px, 73px, 0);
  }
  25% {
    clip: rect(75px, 9999px, 57px, 0);
  }
  30% {
    clip: rect(89px, 9999px, 38px, 0);
  }
  35% {
    clip: rect(6px, 9999px, 68px, 0);
  }
  40% {
    clip: rect(63px, 9999px, 17px, 0);
  }
  45% {
    clip: rect(55px, 9999px, 49px, 0);
  }
  50% {
    clip: rect(98px, 9999px, 28px, 0);
  }
  55% {
    clip: rect(18px, 9999px, 57px, 0);
  }
  60% {
    clip: rect(18px, 9999px, 64px, 0);
  }
  65% {
    clip: rect(35px, 9999px, 77px, 0);
  }
  70% {
    clip: rect(31px, 9999px, 59px, 0);
  }
  75% {
    clip: rect(30px, 9999px, 38px, 0);
  }
  80% {
    clip: rect(13px, 9999px, 52px, 0);
  }
  85% {
    clip: rect(63px, 9999px, 91px, 0);
  }
  90% {
    clip: rect(96px, 9999px, 67px, 0);
  }
  95% {
    clip: rect(24px, 9999px, 13px, 0);
  }
  100% {
    clip: rect(5px, 9999px, 29px, 0);
  }
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(20px, 9999px, 91px, 0);
  }
  5% {
    clip: rect(20px, 9999px, 44px, 0);
  }
  10% {
    clip: rect(84px, 9999px, 55px, 0);
  }
  15% {
    clip: rect(40px, 9999px, 93px, 0);
  }
  20% {
    clip: rect(37px, 9999px, 73px, 0);
  }
  25% {
    clip: rect(75px, 9999px, 57px, 0);
  }
  30% {
    clip: rect(89px, 9999px, 38px, 0);
  }
  35% {
    clip: rect(6px, 9999px, 68px, 0);
  }
  40% {
    clip: rect(63px, 9999px, 17px, 0);
  }
  45% {
    clip: rect(55px, 9999px, 49px, 0);
  }
  50% {
    clip: rect(98px, 9999px, 28px, 0);
  }
  55% {
    clip: rect(18px, 9999px, 57px, 0);
  }
  60% {
    clip: rect(18px, 9999px, 64px, 0);
  }
  65% {
    clip: rect(35px, 9999px, 77px, 0);
  }
  70% {
    clip: rect(31px, 9999px, 59px, 0);
  }
  75% {
    clip: rect(30px, 9999px, 38px, 0);
  }
  80% {
    clip: rect(13px, 9999px, 52px, 0);
  }
  85% {
    clip: rect(63px, 9999px, 91px, 0);
  }
  90% {
    clip: rect(96px, 9999px, 67px, 0);
  }
  95% {
    clip: rect(24px, 9999px, 13px, 0);
  }
  100% {
    clip: rect(5px, 9999px, 29px, 0);
  }
}

.glitchText:after {
  content: attr(datatext);
  white-space: pre;
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 #07e625;
  top: 0;
  color: #000000;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

.glitchText:before {
  content: attr(datatext);
  position: relative;
  left: -2px;
  text-shadow: 1px 0 #4e73df;
  top: 0;
  color: #000000;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}
*/


.ParticlesContainer {
  position: absolute;
}

.ParticlesElem {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


.NivelHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 20px;
  /*background-color: #21293e;*/
}


.NivelDificultadYSWC {
  display: flex;
  gap: 30px;
  margin-top: 0;
  margin-bottom: 2px;
  flex-wrap: wrap; /* Envuelve los elementos a una nueva línea si no hay suficiente espacio */
  justify-content: center; /* Centra horizontalmente los elementos */
  align-items: center; /* Centra verticalmente los elementos */
  max-width: 94%;
}


h4, h5 {
  margin: 0; /* Elimina los márgenes predeterminados para h4 y h5 */
  text-align: center; /* Centra el texto horizontalmente */
  line-height: 1.5; /* Controla la altura de línea para centrar verticalmente */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Asegura que ambos elementos ocupen la misma altura */
}

.Botones {
  display: flex;
  gap: 30px;
  margin-top: 25px;
  margin-bottom: 30px;
}

.NivelPesca {
  background-color: #21293e;

  border-color: #c5d1eb;
  border-width: 1px; /* Ancho del borde */
  border-style: solid; /* Estilo del borde */

  border-radius: 10px;
  padding: 20px;
  margin-bottom: 60px;
  margin-top: -20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.PescaInputYBoton {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.PescaInput {
  width: 465px;
}


.Success {
  color: #9fef00;
  border-color: #9fef00;
  box-shadow: 0 0 5px #9fef00;
}

.Error {
  color: #ff8484;
  border-color: #ff8484;
  box-shadow: 0 0 5px #ff8484;
}


.PescaEstado {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.PescaEstadoContainer {
  position: relative;
  min-height: 25px; /* Altura mínima para reservar espacio */
}


.AccordionInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;
}

.ItemAccordeon {
  width: 94%;
}

.NivelCodigo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: auto; /* Agrega desbordamiento horizontal automático */
  /*margin-bottom: 4.5%;*/
  margin-bottom: 60px;
}

.NivelCodigo pre,
.NivelCodigo code.hljs {
  max-width: 94%; /* Establece un ancho máximo del 90% del contenedor */
  /*margin: 0 auto; /* Centra horizontalmente el elemento */
}


.CodigoNoProvisto {
  background-color: #1a2332;
  border: 2px solid #ff8484;
  color: #ff8484;
  padding: 30px;
  font-size: 18px;
}



.NivelObjetivos {
  max-width: 80%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box; /* Esto asegura que el padding se incluya en el ancho máximo */
  text-align: center; /* Centra los elementos hijos horizontalmente */
  margin-bottom: 50px;
  margin-top: 20px;
  /*background-color: #1a2332;*/
  /*border-radius: 10px;*/
}


.Objetivo {
  text-align: center;
  margin-bottom: 2px;
  overflow-wrap: break-word; /* Esto permite que las palabras se envuelvan dentro del contenedor */
  background-color: #2b3854;
  color: #c5d1eb;
  display: inline-block; /* Cambia el comportamiento de la caja para que solo tenga el tamaño del contenido */
  border-radius: 10px; /* Todos los bordes redondeados con un radio de 10px */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.ObjetivoContainer {
  margin-bottom: 10px;
}

.ObjetivoTitulo {
  color: #c5d1eb;
  margin-bottom: 8px;
  font-size: 20px;
}



.NivelFooter {
  /*display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;*/

  display: flex;
  flex-direction: column;
  margin-top: 2.8%;
}






input {
  background-color: #2b3854; /* Cambia el color de fondo */
  color: #ffffff; /* Cambia el color del texto */
  border: 1px solid #ccc; /* Añade un borde para mayor visibilidad */
  padding: 5px; /* Añade un poco de espacio interno para mejorar el aspecto */
}

/* Estilo para el color del placeholder */
input::placeholder {
  color: #999; /* Cambia el color del texto de marcador de posición */
}

/* Estilo para el input en estado de foco (cuando está seleccionado) */
input:focus {
  outline: none; /* Quita el resplandor predeterminado al hacer clic */
  border-color: #c5f467; /* Cambia el color del borde cuando está enfocado */
  box-shadow: 0 0 5px #9fef00; /* Añade una sombra sutil cuando está enfocado */
}