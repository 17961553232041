.Donar {
  background-color: #141d2b;
  min-height: 100vh; /* Establece el mínimo alto del contenedor principal */
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column; /* Establece la dirección de los elementos secundarios */
  overflow: auto; /* Estilo para el contenedor que podría tener desbordamiento sin quedar el fondo en blanco */
  /*margin-top: -5px;*/
}


.BodyDonar {
  flex-grow: 1; /* Hace que el contenido ocupe todo el espacio restante */
  padding: 0px;
}


.DonarContenedor {
  /*background-color: #21293e;*/
  min-height: 100%;
  display: block;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #c5d1eb;
  margin-top: -25px;
  /*border: 1px solid #9fef00;*/
}

.DonarDireccionContenedor {
  margin-top: 30px;
  margin-bottom: 30px;
}

.DonarDireccionYBoton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 25px;
  /*margin: 0 auto; 
  width: fit-content;*/
}


.DonarDireccion {
  background-color: #21293e;
  border-radius: 5px;
  display: inline-block;
  padding: 3px 10px;
  /*color: #9fef00;*/
  box-shadow: 0 0 5px #9fef00; /* Agregar resplandor exterior con el color especificado */
}



.DonarDireccionEstado {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.DonarDireccionEstadoContenedor {
  position: relative;
  min-height: 25px; /* Altura mínima para reservar espacio */
}


.DonarParrafo {
  margin-bottom: 35px;
}




@media (max-width: 1280px) {
  .DonarContenedor {
    width: 80%;
  }
}


.DonarTitulo {
  color: #9fef00;
  margin-bottom: 10px;
}


.DonarFooter {
  /*display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; */

  padding: 0px;
  background-color: #141d2b;
  color: #9fef00;
  text-align: center;
  /*margin-top: auto; /* Empuja el footer hacia la parte inferior */
  margin-top: 2.8%;
}

.MarginBottom15 {
  margin-bottom: 15px;
}

.MarginTop15 {
  margin-top: 15px;
}

/*
.DonarIconoCategoria {
  margin-left: 5px;
  height: 30px;
}
*/