/*
.Ranking {
  position: relative;
  overflow: hidden;
}
*/

/*
.table>:not(caption)>*>a {
  all: unset;
}
*/

.Ranking {
  background-color: #141d2b;
  min-height: 100vh; /* Establece el mínimo alto del contenedor principal */
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column; /* Establece la dirección de los elementos secundarios */
  overflow: auto; /* Estilo para el contenedor que podría tener desbordamiento sin quedar el fondo en blanco */
}


.BodyRanking {
  flex-grow: 1; /* Hace que el contenido ocupe todo el espacio restante */
  padding: 20px;
}

/*
.BodyRanking {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye uniformemente los elementos en el eje vertical
  align-items: center;
  flex-grow: 1; /* Permite que el contenedor del cuerpo ocupe todo el espacio restante
  margin-bottom: 5vh; /* Añade un margen inferior de 5% del alto de la ventana 
}
*/


/*
.HeaderRanking {
  background-color: #141d2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 20px;
}
*/


.TablaRanking {
  /*max-width: 60%;*/
  max-width: 1000px;
  /*display: inline-block; /* La tabla se ajustará automáticamente según su contenido */
  /*overflow: hidden;*/
  margin: 0 auto; /* Centra la tabla en el contenedor */
  margin-bottom: 4.5%;
}


.Footer {
  padding: 0px;
  background-color: #141d2b;
  color: #9fef00;
  text-align: center;
  margin-top: auto; /* Empuja el footer hacia la parte inferior */
}


.ContenedorRanking {
  /*overflow: auto;*/
  text-align: center; /* Centra la tabla horizontalmente */
}


/*
.ContenedorRanking {
  min-height: 50vh;
  flex-grow: 1; /* Permite que el contenedor del cuerpo ocupe todo el espacio restante

  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye uniformemente los elementos en el eje vertical
  align-items: center;
}
*/

.BotonPlataforma {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  gap: 30px;
}

.MensajeCarga {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #c5d1eb;
  margin-top: 5%;
  margin-bottom: 5%;
}

.NotaCarga {
  color: #ffcc5c;
  margin-top: 3%;
}


table {
  border-collapse: collapse !important; /* Combina los bordes de las celdas */
  background-color: #21293e !important; /* Fondo transparente para la tabla */
  color: #9fef00 !important; /* Color del texto para el contenido de la tabla */
  width: 100% !important; /* Ocupa el ancho completo del contenedor */
}

th, td {
  border: 1px solid #9fef00 !important; /* Borde para las celdas */
  padding: 10px !important; /* Ajusta el relleno según tus necesidades */
  background-color: #21293e !important; /* Fondo transparente para la tabla */
  color: #9fef00 !important; /* Color del texto para el contenido de la tabla */
}

td {
  color: #c5d1eb !important;
}

.tdRedesSociales {
  padding: 7px !important;
}

/* Establece un fondo de color diferente para las filas impares */
table tr:nth-child(odd) td{
  background: #2b3854 !important;
}



/*
.IconoPequeñoRedSocial {
  width: 30px;
  height: 30px;
}
*/

.LinkIconoPequeñoRedSocial img {
  width: 30px; /* Ajusta el tamaño de la imagen según sea necesario */
  height: auto;
  margin-right: 5px;
  margin-left: 5px;
  transition: filter 0.3s; /* Agregar una transición suave al cambio de filtro */
}

.LinkIconoPequeñoRedSocial:hover {
  color: #5cb2ff;
  /*background-color: #2b3854;*/
}

.LinkIconoPequeñoRedSocial:hover img {
  filter: brightness(120%) sepia(1) hue-rotate(195deg); /* Aplicar filtros para cambiar el color de la imagen al hacer hover */
}


/*
.ContenedorIconosRedesSociales {
  display: flex;
  justify-content: center;
  text-align:center;
  gap: 10px;
}


.ContenedorIconosRedesSociales a {
  display: inline-block; /* Cambia los elementos <a> para que se muestren en línea 
  margin: 0 10px; /* Agrega un espacio horizontal entre los elementos 
}
*/



.BuscadorJugadores {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}


.BuscadorJugadores input {
  width: 465px;
}


.BuscadorJugadores img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  opacity: 0.8;
}


.BuscadorJugadores:focus-within img {
  opacity: 1; /* Reducir la opacidad */
}


.ErrorBusqueda {
  color: #ff8484;
  margin-top: 60px;
  margin-bottom: 180px; /* Ocupa el 100% de la altura disponible en su contenedor */
}