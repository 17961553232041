

/* Importa la fuente local en formato .ttf */
@font-face {
  font-family: 'Digital-7';
  src: url('../fonts/digital-7.ttf') format('truetype');
}

@font-face {
  font-family: 'Future-n0t-Found';
  src: url('../fonts/Future n0t Found Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nightmare-Codehack';
  src: url('../fonts/Nightmare Codehack.otf') format('truetype');
}

@font-face {
  font-family: 'Sunday-Bloom';
  src: url('../fonts/Sunday Bloom.ttf') format('truetype');
}

@font-face {
  font-family: 'Sunday-Bloom';
  src: url('../fonts/Sunday Bloom.ttf') format('truetype');
}

@font-face {
  font-family: 'Mad-Hacker';
  src: url('../fonts/Mad Hacker.ttf') format('truetype');
}

@font-face {
  font-family: 'hackerchaos';
  src: url('../fonts/hackerchaos.otf') format('truetype');
}



.NotFound {
  background-color: #141d2b;
  min-height: 100vh; /* Establece el mínimo alto del contenedor principal */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye los elementos verticalmente */
  
}

.ParticlesContainer {
  position: absolute;
}

.ParticlesElem {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


.NotFoundHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.BotonesNotFound {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra verticalmente los botones */
  align-items: center; /* Centra horizontalmente los botones */
  flex-grow: 1; /* Hace que ocupe todo el espacio restante */
  margin-bottom: 60px; /* Espacio entre los botones y el footer */
}






/*
.NotFoundFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 400px;
}
*/