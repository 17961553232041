.VentanaParaMovil {
  background-color: #141d2b;
  min-height: 100vh; /* Establece el mínimo alto del contenedor principal */
  padding-top: 3vh; /* Margen superior ajustable para espaciar del borde superior */
  position: relative; /* Establece el contexto de posicionamiento */
  padding-bottom: 10%;
}

.TextoVentanaParaMovil {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  flex: 1; /* Ocupa todo el espacio vertical disponible */
  padding-top: 10vh;
  max-width: 80%; /* Ancho máximo del contenedor al 80% del ancho de la pantalla */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  font-size: 0.9375rem; /* 15px en unidades relativas */
}


.HeaderMovil {
  text-align: center;
  color: #9fef00;
}








.glitchTextMovil {
  font-size: 70px; /* Tamaño de fuente ajustable */
  overflow: hidden; /* Ocultar el desbordamiento */
  text-overflow: ellipsis; /* Agregar puntos suspensivos si el texto es demasiado largo */

  color: #c5d1eb;
  /*font-family: 'Mad-Hacker';*/
  font-family: 'Positive-System';
  position: relative;
  white-space: nowrap; /* Evita el salto de línea automático */
  text-align: center; /* Centra el texto horizontalmente */
  /*line-height: 1;*/
  /*width: 12.5rem;*/
}

.glitchTextMovil:after {
  content: attr(datatext);
  white-space: pre;
  position: absolute;
  /*left: 50px;*/
  /*left: 15.5%;*/
  transform: translateX(-99%);   /* Centra el elemento horizontalmente en relación con su contenedor. Alternativa de left que funciona bien para todas las resoluciones */
  text-shadow: -1px 0 #c5d1eb;
  top: 0;
  color: #9fef00;
  /*background: #f8f9fc;*/
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

.glitchTextMovil:before {
  content: attr(datatext);
  position: relative;
  /*left: -0%;*/
  transform: translateX(0%);   /* Centra el elemento horizontalmente en relación con su contenedor */
  text-shadow: 1px 0 #4e73df;
  top: 0;
  color: #9fef00;
  /*background: #f8f9fc;*/
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}


.SiglasPlataformaMovil {
  font-size: 70px; /* Tamaño de fuente ajustable */
  white-space: nowrap; /* Evitar el corte de palabras */
  overflow: hidden; /* Ocultar el desbordamiento */
  text-overflow: ellipsis; /* Agregar puntos suspensivos si el texto es demasiado largo */
}

.NombrePlataformaMovil {
  font-size: 1.125rem; /* 18px en unidades relativas */
}

.FooterMovil {
  font-size: 0.75rem;
  width: 100%;
  background-color: #21293e;
  color: #9fef00;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 15px;
  border-top: 1px solid #9fef00; /* Agregar borde superior */
}