.ventanaCambiarRedContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 4;
  display: none;
}

.ventanaCambiarRed {
  color: #ff8484;
  background-color: #1a2332;
  box-shadow: #ff8484 0px 0px 60px;
  position: fixed;
  top: 20%;
  width: 40%;
  left: 30%;
  right: 30%;
  display: inherit;
  text-align: center;
  /*background-color: var(--primary-color);*/
  /*background-color: rgb(255, 255, 255);*/
  z-index: 5;
  padding: 2% 2% 2% 2%;
  border: 2px solid var(--secondary-color);
  border-radius: 6px;
}