.Autenticador {
  background-color: #141d2b;
  min-height: 100vh; /* Establece el mínimo alto del contenedor principal */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye uniformemente los elementos en el eje vertical */
}


.BodyRegistroAutenticador {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye uniformemente los elementos en el eje vertical */
  align-items: center;
  flex-grow: 1; /* Permite que el contenedor del cuerpo ocupe todo el espacio restante */
  margin-bottom: 5vh auto; /* Añade un margen inferior de 5% del alto de la ventana */
}



.TextoRegistroAutenticador,
.BotonesRegistroAutenticador,
.NotaRegistroAutenticador {
  text-align: center;
  width: 80%;
  max-width: 690px;
  margin-bottom: 20px;
}


.RegistroAutenticador {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  /*height: 10vh;*/
  /*margin-top: 110px;*/
}


@media (min-width: 1700px) {
  .BodyRegistroAutenticador {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
}

@media (min-width: 1900px) {
  .BodyRegistroAutenticador {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
}

@media (min-width: 2300px) {
  .BodyRegistroAutenticador {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
}

@media (min-width: 2700px) {
  .BodyRegistroAutenticador {
    margin-top: 15vh;
    margin-bottom: 15vh;
  }
}

@media (min-width: 3100px) {
  .BodyRegistroAutenticador {
    margin-top: 12vh;
    margin-bottom: 12vh;
  }
}






.BodyLoginAutenticador {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye uniformemente los elementos en el eje vertical */
  align-items: center;
  flex-grow: 1; /* Permite que el contenedor del cuerpo ocupe todo el espacio restante */
  margin-bottom: 5vh; /* Añade un margen inferior de 5% del alto de la ventana */
}

.TextoLoginAutenticador {
  text-align: center;
  width: 80%;
  max-width: 690px;
  margin-top: 8vh; /* Añade un margen inferior de 5% del alto de la ventana */
}






.BodyDesbloqueoMetaMaskAutenticador {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye uniformemente los elementos en el eje vertical */
  align-items: center;
  flex-grow: 1; /* Permite que el contenedor del cuerpo ocupe todo el espacio restante */
  margin-bottom: 5vh; /* Añade un margen inferior de 5% del alto de la ventana */
}

.TextoDesbloqueoMetaMaskAutenticador {
  text-align: center;
  width: 80%;
  max-width: 690px;
  margin-top: 8vh; /* Añade un margen inferior de 5% del alto de la ventana */
}



/*
.TextoAutenticador {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 15vh;
}

.BotonesAutenticador {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 35vh;
}

.NotaAutenticador {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 5vh;
}
*/


.MensajeErrorAutenticador {
  color: #ff8484;
  text-align: center;
}