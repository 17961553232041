.card {
  width: 306px;
  height: 236px;
  /* background-color: transparent; */
  /*transition: background-color 0.3s ease-in-out; Agregamos una transición al color de fondo */
  transition: background-color 0.2s ease, border-color 0.2s ease, text-shadow 0.2s ease;
}


.card-title {
  color: #c5d1eb !important;
  font-size: 25.1px;
  
}


.card-text {
  color: #c5d1eb;
}


.card.nivelCompleto {
  background-color: #21293e; /* Verde */
  border: 2px solid #9fef00;
  text-shadow: 1px 1px 1px #9fef00;
}

.card.nivelIncompleto {
  background-color: #21293e; /* Rojo */
  border: 2px solid #ff8484;
  text-shadow: 1px 1px 1px #ff8484;
}

.card.nivelCompleto:hover {
  background-color: #2b3854; /* Verde */
  border: 2px solid #9fef00;
  text-shadow: 1px 1px 1px #9fef00;
}

.card.nivelIncompleto:hover {
  background-color: #2b3854; /* Rojo */
  border: 2px solid #ff8484;
  text-shadow: 1px 1px 1px #ff8484;
}



/*
.card:hover {
  background-color: #e0e0e0;
}
*/

/*
.card.nivelCompleto {
  background-color: #bafaba; Verde 
}

.card.nivelIncompleto {
  background-color: #ffc9c9; Rojo 
}

.card.nivelCompleto:hover {
  background-color: #8aff8a; Verde 
}

.card.nivelIncompleto:hover {
  background-color: #f8a9a9; Rojo 
}
*/

.dificultad {
  font-size: 20px;
  text-shadow: 1px 1px 1px black;
}

.dificultad.muy-facil {
  color: #9fef00;
}

.dificultad.facil {
  color: #9fef00; 
}

.dificultad.normal {
  color: #ffcc5c;
}

.dificultad.dificil {
  color: #ff8484;
}

.dificultad.muy-dificil {
  color: #ff8484;
}




/*
.dificultad.muy-facil {
  color: #00FF00;
  text-shadow: 1px 1px 1px black;
}

.dificultad.facil {
  color: rgb(154, 255, 22); 
  text-shadow: 1px 1px 1px black;
}

.dificultad.normal {
  color: yellow;
  text-shadow: 1px 1px 1px black;
}

.dificultad.dificil {
  color: orange;
  text-shadow: 1px 1px 1px black;
}

.dificultad.muy-dificil {
  color: red;
  text-shadow: 1px 1px 1px black;
}
*/